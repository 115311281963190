import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { ApiService } from '@app/shared/services/api.service';

@Injectable()
export class CustomerService {

  private customerApiUrl = 'customers';
  private addressApiUrl = 'address';

  constructor(
    private apiService: ApiService,
  ) { }

  /** Get Customer details*/
  getCustomerInfo(customerId): Observable<any> {
    const url = `${this.customerApiUrl}/${customerId}/`;
    return this.apiService.get(url).pipe(
      map(
        data => {
          return data;
        }
      ));
  }

  updateCustomerInfo(values) {
    if (values.id) {
      const url = `${this.customerApiUrl}/${values.id}/`;
      return this.apiService
        .patch(url, values).pipe(
          map(data => {
            return data;
          })
        );
    }
  }

  getAddressList(customerId): Observable<any> {
    const url = `${this.customerApiUrl}/${customerId}/${this.addressApiUrl}/`;
    return this.apiService.get(url).pipe(
      map(
        data => {
          return data;
        }
      ));
  }

  postAddress(customerId, values): Observable<any> {
    const url = `${this.customerApiUrl}/${customerId}/${this.addressApiUrl}/`;
    return this.apiService.post(url, values).pipe(
      map(
        data => {
          return data;
        }
      ));
  }

  patchAddress(customerId, values): Observable<any> {
    const url = `${this.customerApiUrl}/${customerId}/${this.addressApiUrl}/${values.id}/`;
    return this.apiService.patch(url, values).pipe(
      map(
        data => {
          return data;
        }
      ));
  }

  deleteAddress(customerId, values): Observable<any> {
    console.log(values.id, 'delete');
    const url = `${this.customerApiUrl}/${customerId}/${this.addressApiUrl}/${values.id}/`;
    return this.apiService.delete(url).pipe(
      map(
        data => {
          return data;
        }
      ));
  }

  verifyAmazonMerchant(values): Observable<any> {
    const url = `${this.customerApiUrl}/update_amazon_merchant_id/`;
    return this.apiService.post(url, values).pipe(
      map(
        data => {
          return data;
        }
      ));
  }

}
